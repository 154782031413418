import { api } from "../api";

const getCities = async () => {
  try {
    const response = await api.get("/shared?city=list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getGeolocation = async () => {
  try {
    const response = await api.get("/geoLocation");
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getLocations = async () => {
  try {
    const response = await api.get("/shared?locations=list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const searchReservation = async (params) => {
  try {
    const response = await api.get("/reservation", { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const reservation_init = async (payload) => {
  try {
    const response = await api.post("/reservation/init", payload);
    return response;
  } catch (error) {
    throw error;
  }
};

const post_chekout = async (payload) => {
  try {
    const response = await api.post("/reservation/checkout", payload);
    return response;
  } catch (error) {
    throw error;
  }
};

const get_chekout = async () => {
  try {
    const response = await api.get("/reservation/checkout");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const post_reservation = async (payload) => {
  try {
    const response = await api.post("/reservation", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const post_reservation_admin = async (payload) => {
  try {
    const response = await api.post("/admin/reservation", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const update_driver = async (payload) => {
  try {
    const response = await api.post(
      "/admin/reservation/update_driver",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const get_reservations_admin = async (params) => {
  try {
    const response = await api.get("admin/reservation/list", {
      params: params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const get_reservation_admin = async (id) => {
  try {
    const response = await api.get(`admin/reservation/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const change_status_reservation = async (payload) => {
  try {
    const response = await api.put("/admin/reservation/change_status", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const cancel_reservation_user = async (payload) => {
  const { id } = payload;
  try {
    const response = await api.put(`/reservation/cancel/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  searchReservation,
  getCities,
  getLocations,
  reservation_init,
  get_chekout,
  post_chekout,
  post_reservation,
  get_reservations_admin,
  change_status_reservation,
  cancel_reservation_user,
  post_reservation_admin,
  update_driver,
  getGeolocation,
  get_reservation_admin,
};
