import { formatDate, isValidTime, isValidDateCurrent } from "@/helpers/filters";
import ModalReturn from "../Modais/ModalReturn/ModalReturn.vue";
import imagePolo from "@/assets/polo.png";
import CardResult from "@/components/CardResult/CardResult.vue";
import {
  getLocations,
  searchReservation,
  getGeolocation,
} from "@/services/models/reservation";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import LoadingLinear from "@/components/Modais/LoadingLinear.vue";

export default {
  name: "Reservation",

  components: {
    CardResult,
    LoadingLinear,
    ModalReturn,
  },

  data: () => ({
    locations: [],
    loadingLinear: false,
    ModalReturn: {},
    cities: [],
    cars: [],
    today: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10),
    pickupLocation: null,
    pickupDate: "",
    pickupHour: "",
    menuPickupDate: false,
    menuPickupHour: false,
    returnLocation: "",
    returnDate: "",
    returnHour: "",
    menuReturnDate: false,
    menuReturnHour: false,
    imagePolo: imagePolo,
    rules: [(v) => !!v || "Campo obrigatório"],
    rulesDate: [
      (v) =>
        /^\d{2}\/\d{2}\/\d{4}$/.test(v) ||
        "Formato de data inválido (DD/MM/YYYY)",
      (v) =>
        isValidDateCurrent(v) ||
        "Data inválida. A data precisa ser maior ou igual a atual",
    ],
    rulesHours: [
      (v) => /^\d{2}:\d{2}$/.test(v) || "Formato de hora inválido (HH:MM)",
      (v) => isValidTime(v) || "Hora inválida",
    ],
    availableTimes: [],
  }),

  computed: {
    ...mapGetters("reservation", ["reservationDate"]),

    locationsWithFormatted() {
      const formattedLocations = this.locations
        .map((location) => ({
          ...location,
          coords: this.extractCoordinates(location.geolocation),
          fullName: `${location.city_name}, ${location.state_name}`,
        }))
        .sort((a, b) => a.fullName.localeCompare(b.fullName));
      return formattedLocations;
    },

    pickupDateFormated() {
      return formatDate(this.pickupDate);
    },

    returnDateFormated() {
      return formatDate(this.returnDate);
    },
  },

  methods: {
    ...mapActions("reservation", ["reservationDates"]),

    async searchCar() {
      this.loadingLinear = true;
      this.cars = [];

      if (!this.pickupLocation || !this.pickupHour || !this.returnHour) {
        this.loadingLinear = false;
        this.ModalReturn = {
          showModal: true,
          status: "error",
          title: "A cidade e os horários não podem ser vazios",
        };
        return;
      }

      const pickup_date_hour = moment(`${this.pickupDate} ${this.pickupHour}`);
      const return_date_hour = moment(`${this.returnDate} ${this.returnHour}`);

      const startHour = pickup_date_hour.format("HH:mm:ss.SSS");
      const endHour = return_date_hour.format("HH:mm:ss.SSS");

      const params = {
        location: this.pickupLocation,
        scheduled_start_time: `${this.pickupDate} ${startHour}`,
        scheduled_end_time: `${this.returnDate} ${endHour}`,
      };

      try {
        const response = await searchReservation(params);

        if (!response || !response.success) {
          this.ModalReturn = {
            showModal: true,
            status: "error",
            title: response?.message || "Erro desconhecido",
          };
        } else {
          this.reservationDates({
            pickupLocation: this.pickupLocation,
            pickupDate: this.pickupDate,
            returnDate: this.returnDate,
            pickupHour: this.pickupHour,
            returnHour: this.returnHour,
          });
          this.cars = response.data;
        }
      } catch (error) {
        console.error("Erro ao buscar reservas:", error);
        this.ModalReturn = {
          showModal: true,
          status: "error",
          title: "Erro ao buscar reservas",
        };
      } finally {
        this.loadingLinear = false;
      }
    },

    async extractCoordinates(url) {
      if (url) {
        const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
        const match = url.match(regex);
        if (match) {
          return {
            latitude: parseFloat(match[1]),
            longitude: parseFloat(match[2]),
          };
        } else {
          return null;
        }
      }
    },

    async onLocationChange(pickupLocation) {
      let foundLocation;
      if (pickupLocation) {
        foundLocation =
          this.locations.find(
            (loc) => loc.neighborhood === pickupLocation.neighborhood
          ) ||
          this.locations.find(
            (loc) => loc.city_name === pickupLocation.city_name
          ) ||
          this.locations.find(
            (loc) => loc.state_name === pickupLocation.state_name
          ) ||
          null;
        if (foundLocation) {
          this.pickupLocation = {
            ...foundLocation,
            coords: await this.extractCoordinates(foundLocation.geolocation),
            fullName: `${foundLocation.city_name}, ${foundLocation.state_name}`,
          };
        }

        this.searchCar();
      }
    },

    generateAvailableTimes() {
      const availableTimes = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          const time = `${String(hour).padStart(2, "0")}:${String(
            minute
          ).padStart(2, "0")}`;
          availableTimes.push(time);
        }
      }
      this.availableTimes = availableTimes;
    },
  },

  async created() {
    this.loadingLinear = true;

    if (this.reservationDate) {
      this.pickupLocation = this.reservationDate.pickupLocation;
      this.pickupDate = this.reservationDate.pickupDate;
      this.returnDate = this.reservationDate.returnDate;
      this.pickupHour = this.reservationDate.pickupHour;
      this.returnHour = this.reservationDate.returnHour;
    } else {
      this.pickupDate = moment().add(1, "day").format("YYYY-MM-DD");
      this.returnDate = moment().add(2, "day").format("YYYY-MM-DD");
      this.pickupHour = "10:30";
      this.returnHour = "10:30";
    }
    try {
      const getGeolacationIP = await getGeolocation();
      const { locations } = await getLocations();

      this.locations = locations;
      if (getGeolacationIP?.city && locations) {
        for (let city of locations) {
          if (city.city_name === getGeolacationIP.city.names["pt-BR"]) {
            this.pickupLocation = city[0] || city;
          }
        }
      }
      if (!this.pickupLocation) {
        this.pickupLocation = locations[0];
      }
      this.onLocationChange(this.pickupLocation);
      this.generateAvailableTimes();
    } catch (error) {
      console.error(error.message);
    }
  },
};
