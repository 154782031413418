export default async function (to, from, next) {
  let userData = null;

  try {
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      userData = JSON.parse(userDataString);
    }
  } catch (error) {
    // Se houver erro na análise do JSON, limpa o dado inválido do localStorage
    localStorage.removeItem("userData");
  }

  if (!userData) {
    // Se o usuário não estiver autenticado e tentar acessar uma rota protegida
    if (
      to.matched.some(
        (record) =>
          record.meta.requiresAuth || record.meta.dashboardRequiresAuth
      )
    ) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next(); // Se a rota não exigir autenticação, continua
    }
    return;
  }

  // Verifica o acesso ao dashboard
  if (to.matched.some((record) => record.meta.dashboardRequiresAuth)) {
    if (
      userData.auth &&
      (userData.permissions.is_car_rental_admin ||
        userData.permissions.is_super_admin)
    ) {
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
    return;
  }

  // Verifica o acesso à página que requer autenticação
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (userData.auth) {
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
    return;
  }

  // Para outras rotas, simplesmente segue em frente
  next();
}
