import { api } from "../api";

const login = async (payload) => {
  try {
    const response = await api.post("/user/login", payload);
    if (response.data.success && response.data.token) {
      document.cookie = `connect.sid=${response.data.token}`;
      return {
        success: true,
        token: response.data.token,
        message: "Bem vindo ao Cartya!",
      };
    } else {
      const errorMessage =
        response.data.err || "Não foi possível efetuar o login!";
      return { success: false, message: errorMessage };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data.err || "Não foi possível efetuar o login!";
    return { success: false, message: errorMessage };
  }
};

const logout = async () => {
  try {
    await api.get("/user/logout");
  } catch (error) {}
};

const login_google = async (payload) => {
  console.log(payload);
  try {
    const response = await api.post("/user/login/google", payload);
    if (response.data.success && response.data.token) {
      document.cookie = `connect.sid=${response.data.token}`;
      return {
        success: true,
        token: response.data.token,
        message: "Bem vindo ao Cartya!",
      };
    } else {
      const errorMessage =
        response.data.err || "Não foi possível efetuar o login!";
      return { success: false, message: errorMessage };
    }
  } catch (error) {
    const errorMessage =
      error.response?.data.err || "Não foi possível efetuar o login!";
    return { success: false, message: errorMessage };
  }
};

const forgotPassword = async (payload) => {
  try {
    const response = await api.post("/user/forgot_password/send", payload);
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data.err || "Não foi possível enviar o email!";
    return { success: false, message: errorMessage };
  }
};

const resetPassword = async (payload) => {
  var token = new URLSearchParams(window.location.search).get("token");
  const body = {
    password: payload,
    confirm_password: payload,
    token: token,
  };
  try {
    const response = await api.post("/user/forgot_password/reset", body);
    if (response.data.success) {
      return response.data;
    } else {
      return response.data.err;
    }
  } catch (error) {
    const errorMessage = error.message || "Não foi possível efetuar o login!";
    return { success: false, message: errorMessage };
  }
};

export { login, logout, resetPassword, forgotPassword, login_google };
